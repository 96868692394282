<template>
  <VRow>
    <VCol>
      <ReportsTableContainer />
    </VCol>
  </VRow>
</template>

<script>
// eslint-disable-next-line
import ReportsTableContainer from '../../components/reports/ReportsTableContainer.vue';

export default {
  name: 'ReportsList',

  components: {
    ReportsTableContainer,
  },

  inject: ['Names'],
};
</script>
