<template>
  <ReportsTable
    :reports="reports"
    :loading="loading"
    @update-date-time="handleUpdateDateTime"
  />
</template>

<script>
import ReportsTable from './ReportsTable.vue';

export default {
  name: 'ReportsTableContainer',

  components: {
    ReportsTable,
  },

  inject: ['Names'],

  data() {
    return {
      reports: [],
      loading: false,
    };
  },

  methods: {
    fetch(startDate = null) {
      this.loading = true;

      this.$di.api.ReportServiceApi
        .reportsGet({
          startDate,
        })
        .then(({ reports }) => {
          this.reports = reports;
        })
        .catch(this.$di.notify.errorHandler)
        .finally(() => {
          this.loading = false;
        });
    },
    handleUpdateDateTime(value) {
      this.fetch(value);
    },
  },
};
</script>
